import { useState, useEffect, useContext, useRef } from 'react'
import { faArrowLeft, faTicketAlt, faUtensils } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Elements, useElements, useStripe, LinkAuthenticationElement, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'

import { BasketContext } from '../../basket/BasketContext'
import priceToString from '../../../util/priceToString'
import { LocationContext } from '../../common/LocationContext'
import config from '../../../config'
import EventPaymentAmount from 'components/event/EventPaymentAmount'
import Loading from 'components/common/Loading'

const EventPayment = ({ goToTickets, orderScrollRef, eventMenu, partyName, eventDate, specialRequirements, customData }) => {
  const [stripePromise, setStripePromise] = useState()
  const [stripeIntent, setStripeIntent] = useState()

  const { location } = useContext(LocationContext)

  useEffect(() => {
    if (location) {
      if (location.config.canProcessPayment === true) {
        setStripePromise(loadStripe(location.config.stripePublicKey))

        // Create initial intent for £1. This gets updated as users selects payment option.
        axios.post(
          `${config.baseOrderUrl}/payment/intent`,
          {
            amount: 100,
            locationId: location.locationId,
            menuId: eventMenu.id,
            bookingFee: 0,
            paymentType: "NEW",
          }
        ).then(res => {
          setStripeIntent(res.data)
        })
      }
    }
  }, [location])

  if (!stripePromise || !stripeIntent) return <PaymentFormSkeleton />

  return (
    <div className="margin-top--4">
      <h2 ref={orderScrollRef}>Review and pay</h2>
      <div className="event-payment__back-btn" onClick={goToTickets}>
        <FontAwesomeIcon icon={faArrowLeft} />
        <span>Back to tickets</span>
      </div>
      <div className="event-payment__wrapper">
        <Elements stripe={stripePromise} options={{ clientSecret: stripeIntent.clientSecret }}>
          <PaymentForm
            eventMenu={eventMenu}
            partyName={partyName}
            eventDate={eventDate}
            specialRequirements={specialRequirements}
            customData={customData}
            stripeIntent={stripeIntent}
            setStripeIntent={setStripeIntent}
          />
        </Elements>
        <Basket eventMenu={eventMenu} />
      </div>
    </div>
  )
}

const PaymentFormSkeleton = () => (
  <div className="event-payment__form-wrapper">
    <form className="event-payment__form">
      <div className="skeleton skeleton__input-label"></div>
      <div className="skeleton skeleton__input-text"></div>

      <div className="skeleton skeleton__input-label"></div>
      <div className="skeleton skeleton__input-text"></div>

      <div className="skeleton skeleton__input-label"></div>
      <div className="skeleton skeleton__input-text"></div>

      <div className="skeleton skeleton__input-label"></div>
      <div className="skeleton skeleton__input-text"></div>

      <div className="skeleton skeleton__input-label"></div>
      <div className="skeleton skeleton__input-text"></div>
    </form>
  </div>
)

const PaymentForm = ({ eventMenu, partyName, eventDate, specialRequirements, customData, stripeIntent, setStripeIntent }) => {
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)
  const [hasCompletedPayment, setHasCompletedPayment] = useState(false)

  const [error, setError] = useState()

  const elements = useElements()
  const stripe = useStripe()

  const { basket, numberOfItemsInBasketByRef } = useContext(BasketContext)
  const { location, menuIdLookingAt } = useContext(LocationContext)

  const [isStripeReady, setIsStripeReady] = useState(false)

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment')
      if (element) {
        element.on('ready', () => {
          setIsStripeReady(true)
        })
      }
    }
  }, [elements])

  const cardNameRef = useRef()
  const emailRef = useRef()
  const termsRef = useRef()
  const phoneRef = useRef()
  const amountToPayRef = useRef(eventMenu.depositPerItem ? 'deposit' : 'full')

  useEffect(() => {
    updateStripeIntent(amountToPayForOrder(eventMenu.depositPerItem ? 'deposit' : 'full'))
  }, [])

  async function updateStripeIntent(amount) {
    const intent = await axios.put(
      `${config.baseOrderUrl}/payment/intent/${stripeIntent.intentId}`,
      {
        locationId: location.locationId,
        changeValues: {
          amount: amount
        }
      }
    )
    setStripeIntent(intent.data)
    return intent.data
  }

  async function changeAmountToPay(e) {
    try {
      await updateStripeIntent(amountToPayForOrder(e))
      amountToPayRef.current = e
    } catch (error) {
      console.log(error);
    }
  }

  const hasFee = !!eventMenu.defaultItemFee
  const hasDeposit = !!eventMenu.depositPerItem

  function getEventFee() {
    if (!eventMenu.defaultItemFee) return 0
    const itemsInBasket = numberOfItemsInBasketByRef.current
    return itemsInBasket * eventMenu.defaultItemFee > eventMenu.maxTotalItemFee ? eventMenu.maxTotalItemFee : itemsInBasket * eventMenu.defaultItemFee
  }

  function getEventDeposit() {
    if (!eventMenu.depositPerItem) return 0
    const itemsInBasket = numberOfItemsInBasketByRef.current
    return itemsInBasket * eventMenu.depositPerItem
  }

  function isOrderValid() {
    setError(null)
    let isValid = true
    const errors = []

    if (!cardNameRef.current.value || cardNameRef.current.value.length === 0) {
      isValid = false
      errors.push('name on the card')
    }

    if (!emailRef.current || emailRef.current.length === 0) {
      isValid = false
      errors.push('email address')
    }

    if (!phoneRef.current.value || phoneRef.current.value.length === 0) {
      isValid = false
      errors.push('phone number')
    }

    if (!termsRef.current.checked || termsRef.current.checked === false) {
      isValid = false
      errors.push('agree with the terms and conditions')
    }

    if (Object.keys(basket.contents).length === 0) {
      isValid = false
      errors.push('you must order at least one product')
    }

    if (!isValid) setError(`Please provide ${errors.join(', ').replace(/, ([^,]*)$/, ' and $1')}.`)
    return isValid
  }

  const amountToPayForOrder = (type) => {
    const fee = getEventFee()
    if (type === 'deposit' && eventMenu.depositPerItem) return getEventDeposit() + fee
    return basket.getTotalPrice() + fee
  }

  async function createOrder(e) {
    e.preventDefault()
    if (!isOrderValid() || isProcessingPayment) return

    if (!location?.locationId || !menuIdLookingAt) {
      setError('Sorry, we could not process your payment. Please try again.')
      return
    }

    setIsProcessingPayment(true)

    // Stripe to fetch intent updates
    await elements.fetchUpdates()

    const items = {}
    for (const [key, value] of Object.entries(basket.contents)) {
      if (value.quantity > 0) {
        items[key] = {
          quantity: value.quantity,
          size: value.size,
          menuId: menuIdLookingAt,
          menuItemId: value.id,
        }
      }
    }

    const orderToPlace = {
      totalCost: basket.getTotalPrice(),
      amountToPay: amountToPayForOrder(amountToPayRef.current),
      bookingFee: getEventFee(),
      locationId: location.locationId,
      items: items,
      customer: {
        name: partyName,
        email: emailRef.current,
        phone: phoneRef.current.value,
      },
      // TODO - how to we determine the destination?
      destination: null,
      deliveryDate: eventDate,
      notes: specialRequirements,
      menuId: menuIdLookingAt,
      customDataFields: customData,
      stripeIntentId: stripeIntent.intentId
    }

    const res = await axios.post(`${config.baseOrderUrl}/preorder`, orderToPlace)
    if (res?.status === 200) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "http://localhost:3000/event",
        },
        redirect: 'if_required'
      });

      if (error) {
        if (error.message) {
          setError(`Error - ${error.message}`)
        } else {
          setError(`There was an error processing your payment. Please check your card details and try again.`)
        }

        setIsProcessingPayment(false)
      } else {
        setHasCompletedPayment(true)
      }
    } else {
      setError(`There was an error processing your payment. Please check your card details and try again.`)
      setIsProcessingPayment(false)
    }
  }

  if (hasCompletedPayment) {
    return (
      <div className="event-payment__form-wrapper">
        <h3>Thank you!</h3>
        <p>Your order is now with our team. A confirmation and a receipt have been sent to the provided email.</p>
      </div>
    )
  }

  return (
    <div className="event-payment__form-wrapper">
      {!isStripeReady && <Loading />}

      <form className="event-payment__form" onSubmit={createOrder}>
        {isStripeReady && (
          <>
            <EventPaymentAmount
              onChange={changeAmountToPay}
              depositAmount={amountToPayForOrder('deposit')}
              fullAmount={amountToPayForOrder('full')}
              eventDates={eventMenu.eventDetails.eventDateTime}
              hasFee={hasFee}
              hasDeposit={hasDeposit}
            />
            <LinkAuthenticationElement onChange={(e) => emailRef.current = e.value.email} />

            <label>Phone number</label>
            <input type="tel" placeholder="07000000000" ref={phoneRef} disabled={isProcessingPayment} />

            <label>Name on card</label>
            <input type="text" placeholder="J. Smith" ref={cardNameRef} disabled={isProcessingPayment} />
          </>
        )}

        <PaymentElement />

        {isStripeReady && (
          <>
            <div className="terms-wrapper">
              <input type="checkbox" id="terms" name="terms" ref={termsRef} disabled={isProcessingPayment} />
              <label htmlFor="terms">I confirm that I have read and accept the <a href="https://servd.uk/terms-of-service" target="_blank" rel="noreferrer">terms of service</a> and the <a href="https://servd.uk/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a>.</label>
            </div>

            {!!error && <ErrorComponent error={error} />}
            {isProcessingPayment ? <div className="payment-info">Do not close this page until your payment has been completed.</div> : ''}
            <input type="submit" value={isProcessingPayment ? 'Processing payment...' : 'Pay'} id="submit-payment-btn" disabled={isProcessingPayment} />
          </>
        )}
      </form>
    </div>
  )
}

const ErrorComponent = ({ error }) => {
  return <div className="error-wrapper"><p className="error error-animation">{error}</p></div>
}

const Basket = ({ eventMenu }) => {
  const { basket, numberOfItemsInBasketByRef } = useContext(BasketContext)

  const hasFee = !!eventMenu.defaultItemFee

  function getEventFee() {
    if (!eventMenu.defaultItemFee) return 0
    const itemsInBasket = numberOfItemsInBasketByRef.current
    return itemsInBasket * eventMenu.defaultItemFee > eventMenu.maxTotalItemFee ? eventMenu.maxTotalItemFee : itemsInBasket * eventMenu.defaultItemFee
  }

  return (
    <div className="event-payment__basket-wrapper">
      <label>Summary</label>
      {Object.keys(basket.contents).map(basketItem => {
        return <BasketLine item={basket.contents[basketItem]} key={basketItem} hasFee={hasFee} />
      })}
      {hasFee && (
        <div className="event-payment__total-wrapper">
          <span className="event-payment__total-label">Booking fee</span>
          <span>{priceToString(getEventFee())}</span>
        </div>
      )}
      <div className="event-payment__total-wrapper">
        <span className="event-payment__total-label">Total</span>
        <span>{priceToString(basket.getTotalPrice() + getEventFee())} <span className="event-payment__total-vat">inc. VAT</span></span>
      </div>
    </div>
  )
}

const BasketLine = ({ item, hasFee }) => {
  const { size, quantity, section, product } = item
  const { name } = product

  return (
    <div className="event-ticket__layout event-ticket__wrapper">
      <div className="event-ticket__details">
        <div className="event-ticket__details__name-wrapper">
          <div className="event-ticket__details__icon">
            <FontAwesomeIcon icon={section.toLowerCase() === 'tickets' ? faTicketAlt : faUtensils} />
          </div>
          <div className="event-ticket__details__name">
            <span className="event-ticket__details__name-label">{name}</span>
            <span>{size.name}</span>
          </div>
        </div>
        <div>
          <span className="event-ticket__details__price">{priceToString(size.price)} {hasFee ? <span className="event-ticket__details__price-vat">+ fee*</span> : null}</span>
          <span className="event-ticket__details__price">x {quantity}</span>
        </div>
      </div>
    </div>
  )
}

export default EventPayment
